<div *ngIf="component.data.questions.length == 0" class="quiz-no-questions">
  <div class="text-center display-4">No questions yet</div>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
  >
    <path
      d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
    ></path>
  </svg>
</div>
<div class="quiz-container">
  <div class="quiz-content">
    <div class="task-obscure" *ngIf="completed && component.data.obscure"></div>
    <div
      class="quiz-pager"
      *ngIf="!component.data?.hideStatusIndicator"
      [hidden]="
        component.data.questions.length &&
        qNum == +component.data.questions.length + 1
      "
    >
      <div
        class="quiz-pager-content{{
          component.style?.statusIndicatorAlign
            ? ' quiz-pager-content-' + component.style?.statusIndicatorAlign
            : ''
        }}"
      >
        <div class="indicator-text" *ngIf="component.data?.showTextIndicator">
          You have answered {{ qNum - 1 }}/{{
            component.data.questions.length
          }}
          questions, so far you have answered {{ score }} correctly
        </div>
        <div
          *ngFor="let question of component.data.questions; let index = index"
          [ngClass]="{ current: qNum == index + 1 }"
          [ngClass]="{
            correct: correct.indexOf(question) !== -1,
            incorrect:
              correct.indexOf(question) == -1 &&
              answered.indexOf(question) !== -1
          }"
          style="{{
            component.style.statusIndicatorScale
              ? 'font-size:' +
                component.style.statusIndicatorScale +
                '%;line-height:1;'
              : ''
          }}"
        >
          <i
            class="bx bxs-circle"
            *ngIf="answered.indexOf(question) == -1"
            style="{{
              component.style.statusIndicatorUnansweredColor &&
              qNum != index + 1
                ? 'color:' +
                  component.style.statusIndicatorUnansweredColor +
                  ';'
                : ''
            }}{{
              component.style.statusIndicatorCurrentColor && qNum == index + 1
                ? 'color:' + component.style.statusIndicatorCurrentColor + ';'
                : ''
            }}"
          ></i>
          <i
            class="bx bxs-check-circle"
            style="{{
              component.style.statusIndicatorAnsweredCorrectColor
                ? 'color:' +
                  component.style.statusIndicatorAnsweredCorrectColor +
                  ';'
                : ''
            }}"
            *ngIf="correct.indexOf(question) !== -1"
          ></i>
          <i
            class="bx bxs-x-circle"
            style="{{
              component.style.statusIndicatorAnsweredIncorrectColor
                ? 'color:' +
                  component.style.statusIndicatorAnsweredIncorrectColor +
                  ';'
                : ''
            }}"
            *ngIf="
              correct.indexOf(question) == -1 &&
              answered.indexOf(question) !== -1
            "
          ></i>
        </div>
      </div>
    </div>
    <div
      *ngFor="let question of component.data.questions; let index = index"
      [hidden]="qNum != index + 1"
    >
      <div
        *ngIf="!component.data?.hideIndicator"
        style="{{
          component.style.indicatorColor
            ? 'color:' + component.style.indicatorColor + ';'
            : ''
        }}{{
          component.style.indicatorScale
            ? 'font-size:' + component.style.indicatorScale + '%;line-height:1;'
            : ''
        }}{{
          component.style.indicatorAlign
            ? 'text-align:' + component.style.indicatorAlign + ';'
            : ''
        }}"
      >
        {{ index + 1 }}/{{ component.data.questions.length }}
      </div>

      <div
        *ngIf="
          component.data?.hideIndicator && !component.data?.hideStatusIndicator
        "
      >
        &nbsp;
      </div>
    </div>
    <hr
      class="quiz-divider"
      *ngIf="
        !component.data?.hideDivider &&
        +qNum <= +component.data.questions.length
      "
    />
    <div *ngIf="component.data?.hideDivider" class="py-2"></div>
    <!--div class="text-center" *ngIf="{{question.image}}">
                          <img ng-src="{{assetPath}}assets/{{question.image | thumbnail : 'medium'}}" class="img-responsive" /><br>
                      </div-->

    <div id="quiz-qa-{{ this.uuid }}-{{ component.id }}">
      <div
        *ngFor="let question of component.data.questions; let index = index"
        [hidden]="qNum != index + 1"
      >
        <div
          class="quiz-media"
          *ngIf="question?.media?.url && qNum - 1 == index"
        >
          <ng-container [ngSwitch]="question?.media?.type">
            <ng-container *ngSwitchCase="'image'">
              <img
                src="{{ question?.media?.url }}"
                alt=""
                style="{{
                  component.style?.questionMediaHeight
                    ? 'height:' + component.style?.questionMediaHeight + 'px'
                    : ''
                }}"
              />
            </ng-container>
            <ng-container *ngSwitchCase="'audio'">
              <audio
                style="width: 100%; max-height: 150px"
                src="{{
                  environment.mediaUrl + '/assets/' + question?.media?.url
                }}"
                [attr.autoplay]="true"
                controls
                controlsList="nodownload"
              >
                Your browser does not support the audio element.
              </audio>
            </ng-container>
            <ng-container *ngSwitchCase="'video'">
              <video
                style="width: 100%; max-height: 150px"
                [attr.autoplay]="true"
                [attr.controls]="true"
                controlsList="nodownload"
                playsinline
                style="{{
                  component.style?.questionMediaHeight
                    ? 'height:' + component.style?.questionMediaHeight + 'px'
                    : ''
                }}"
              >
                <source
                  src="{{
                    environment.mediaUrl + '/assets/' + question?.media?.url
                  }}"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </ng-container>
          </ng-container>
        </div>
        <div class="quiz-question" [ngStyle]="getQuestionStyle()">
          <ng-container *ngIf="component.data?.showQNum"
            >{{ qNum }})</ng-container
          >
          <div [innerHTML]="question.question | safeHtml"></div>
        </div>

        <ul
          class="quiz-answers{{
            component.data?.answerLayout
              ? ' quiz-answers-' + component.data?.answerLayout
              : ''
          }}"
          [ngStyle]="getAnswerStyle()"
        >
          <li
            [ngClass]="{
              'selected-correct': answer.selectedState == 'selectedCorrect',
              'selected-incorrect': answer.selectedState == 'selectedIncorrect',
              'unselected-correct': answer.selectedState == 'unselectedCorrect',
              'unselected-incorrect':
                answer.selectedState == 'unselectedIncorrect',
              selected: answer.selectedState == 'selected'
            }"
            *ngFor="let answer of question.answers; let answerIndex = index"
            (click)="onAnswerQuiz(question, [answer], false)"
            tabindex="0"
            class="pointer{{
              component.style?.answerHoverEffect
                ? ' ' + component.style?.answerHoverEffect.name
                : ''
            }}"
          >
            <!--div class="text-center" *ngIf="answer.image">
                                  <img ng-src="{{assetPath}}assets/{{answer.image | thumbnail : 'square'}}" class="img-responsive" width="200" /><br>
                              </div-->

            <div
              class="answer-media"
              *ngIf="answer?.media?.url && qNum - 1 == index"
            >
              <ng-container [ngSwitch]="answer?.media?.type">
                <ng-container *ngSwitchCase="'image'">
                  <img
                    src="{{ answer?.media?.url }}"
                    alt=""
                    style="{{
                      component.style?.answerMediaHeight
                        ? 'height:' + component.style?.answerMediaHeight + 'px;'
                        : 'height:50px;'
                    }}"
                  />
                </ng-container>
              </ng-container>
            </div>

            <div
              style="{{
                component.style?.answerAlign
                  ? 'width:100%;text-align:' + component.style?.answerAlign
                  : ''
              }}"
            >
              <ng-container *ngIf="component.data?.showANum"
                >{{ answerIndex + 1 }})</ng-container
              >
              {{ answer.answer }}
            </div>
          </li>
        </ul>
        <div
          class="small text-center mt-3"
          *ngIf="
            component.data?.answerHint && this.answered.indexOf(question) == -1
          "
        >
          <ng-container *ngIf="countCorrect(question.answers) > 1">
            {{
              component.data?.multipleAnswersLabel
                ? component.data?.multipleAnswersLabel
                : "You can choose more than one answer"
            }}
          </ng-container>
          <ng-container *ngIf="countCorrect(question.answers) == 1">
            {{
              component.data?.singleAnswerLabel
                ? component.data?.singleAnswerLabel
                : "Please select one answer only"
            }}
          </ng-container>
        </div>
        <div
          class="quiz-buttons mt-2 justify-content-center"
          *ngIf="showAnswerBtn"
        >
          <button
            class="btn btn-ys"
            (click)="onAnswerQuiz(currQuestion, answersSelected, true)"
            [disabled]="
              countCorrect(question.answers) != answersSelected?.length && answersSelected?.length<2
            "
          >
            {{
              component.data?.selectAnswersLabel
                ? component.data?.selectAnswersLabel
                : "Confirm"
            }}
          </button>
        </div>

        <div
          *ngIf="
            this.answered.indexOf(question) !== -1 &&
            component.data?.feedbackLayout == 'overlay'
          "
          [ngStyle]="{
          backgroundColor: component?.style?.feedbackBackground ? component?.style?.feedbackBackground : '#fff',
          color: component?.style?.feedbackTextColour? component?.style?.feedbackTextColour : '#333',
        }"
          class="feedback {{
            this.component.feedbackAnimationIn?.name
              ? 'animate__animated animate__repeat-1 animate__' +
                this.component.feedbackAnimationIn?.name
              : ''
          }}"
          style="{{
            this.component.feedbackAnimationIn?.speed
              ? '--animate-duration:' +
                this.component.feedbackAnimationIn?.speed +
                's;'
              : ''
          }}{{
            this.component.feedbackAnimationIn?.repeat
              ? '--animate-repeat:' + this.component.feedbackAnimationIn?.repeat
              : ''
          }}"
        >
          <div class="feedback-title">
            <span
              *ngIf="question.correct"
              style="{{
                component.style?.statusIndicatorAnsweredCorrectColor
                  ? 'color:' +
                    component.style?.statusIndicatorAnsweredCorrectColor
                  : ''
              }}"
            >
              <i class="bx bxs-check-circle"></i>
              {{
                component.data?.correctLabel
                  ? component.data?.correctLabel
                  : "Correct"
              }}</span
            ><span
              *ngIf="!question.correct"
              style="{{
                component.style?.statusIndicatorAnsweredIncorrectColor
                  ? 'color:' +
                    component.style?.statusIndicatorAnsweredIncorrectColor
                  : ''
              }}"
            >
              <i class="bx bxs-x-circle"></i>
              {{
                component.data?.incorrectLabel
                  ? component.data?.incorrectLabel
                  : "Incorrect"
              }}</span
            >
          </div>
          <div class="feedback-content">
            <div
              [innerHtml]="question.feedbackCorrect | safeHtml"
              *ngIf="question.feedbackCorrect && question.correct"
            ></div>

            <div
              [innerHtml]="question.feedbackIncorrect | safeHtml"
              *ngIf="question.feedbackIncorrect && !question.correct"
            ></div>
          </div>
          <div class="feedback-buttons">
            <button
              class="btn btn-ys"
              (click)="onNextQuestion()"
              *ngIf="
                qNum < +component.data.questions.length ||
                (qNum == +component.data.questions.length &&
                  !component.data.disableResults)
              "
            >
              <span *ngIf="qNum < +component.data.questions.length">
                {{
                  component.data?.nextQLabel
                    ? component.data?.nextQLabel
                    : "Next Question"
                }} </span
              ><span *ngIf="qNum == +component.data.questions.length">{{
                component.data?.resultsLabel
                  ? component.data?.resultsLabel
                  : "View Results"
              }}</span>
              <i
                class="bx {{ component.style.nextQIcon }}"
                *ngIf="component.style.nextQIcon"
              ></i>
            </button>
          </div>
        </div>

        <div
          *ngIf="
            this.answered.indexOf(question) !== -1 &&
            component.data?.feedbackLayout != 'overlay'
          "
          class="quiz-buttons"
        >
          <div class="answered">
            <div>
              <span *ngIf="question.correct">{{
                component.data?.correctLabel
                  ? component.data?.correctLabel
                  : "Correct."
              }}</span
              ><span *ngIf="!question.correct">{{
                component.data?.incorrectLabel
                  ? component.data?.incorrectLabel
                  : "Incorrect."
              }}</span>
              <div>
                <div
                  [innerHtml]="question.feedbackCorrect | safeHtml"
                  *ngIf="question.feedbackCorrect && question.correct"
                ></div>

                <div
                  [innerHtml]="question.feedbackIncorrect | safeHtml"
                  *ngIf="question.feedbackIncorrect && !question.correct"
                ></div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-ys"
            (click)="onNextQuestion()"
            *ngIf="
              qNum < +component.data.questions.length ||
              (qNum == +component.data.questions.length &&
                !component.data.disableResults)
            "
          >
            <span *ngIf="qNum < +component.data.questions.length">
              {{
                component.data?.nextQLabel
                  ? component.data?.nextQLabel
                  : "Next Question"
              }} </span
            ><span *ngIf="qNum == +component.data.questions.length">{{
              component.data?.resultsLabel
                ? component.data?.resultsLabel
                : "View Results"
            }}</span>
            <i
              class="bx {{ component.style.nextQIcon }}"
              *ngIf="component.style.nextQIcon"
            ></i>
          </button>
        </div>

        <div
          class="quiz-auto-loader mt-3"
          *ngIf="
            component.data.autoAdvance && answered.indexOf(question) !== -1
          "
          style="{{
            component.style.autoAdvanceColour
              ? 'background-color:' + component.style.autoAdvanceColour
              : ''
          }}"
        ></div>
      </div>

      <div
        *ngIf="
          component.data.questions.length &&
          qNum == +component.data.questions.length + 1
        "
      >
        <div class="quiz-complete">
          <div class="quiz-score">
            {{
              component.data?.youScoredLabel
                ? component.data?.youScoredLabel
                : "You Scored..."
            }}
            <div class="quiz-score-total">
              {{ score }}
              {{
                component.data?.outOfLabel
                  ? component.data?.outOfLabel
                  : "out of"
              }}
              {{ component.data.questions.length }}
            </div>
            <div
              [innerHtml]="component.data.feedback | safeHtml"
              *ngIf="component.data.feedback"
              class="quiz-feedback text-left"
            ></div>
          </div>
          <div class="text-center" *ngIf="!completed">
            <button
              *ngIf="component.data.allowRetake"
              class="btn btn-choice btn-ys me-2"
              (click)="onRetake()"
            >
              <ng-container *ngIf="!component.data.retakeLabel"
                >Retake</ng-container
              >
              <ng-container *ngIf="component.data.retakeLabel">{{
                component.data.retakeLabel
              }}</ng-container>
            </button>

            <button
              class="btn btn-choice btn-ys"
              (click)="onComplete()"
              *ngIf="!component.data.disableComplete"
            >
              <ng-container *ngIf="!component.data.completeLabel"
                >Complete</ng-container
              >
              <ng-container *ngIf="component.data.completeLabel">{{
                component.data.endLabel
              }}</ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
