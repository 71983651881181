import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  publishReplay,
  refCount,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { interval, Subscription } from 'rxjs';
import { ScenarioSession } from '../models/scenario-session.model';
import { ActivatedRoute } from '@angular/router';
import { DataEncryptionService } from './data-encryption.service';
import { nanoid } from 'nanoid';
import { ScenarioService } from './scenario.service';

// for SCORM / xAPI
declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class PlayerSessionService implements OnDestroy {
  sessionData: any = {};
  sessionLog: any = [];
  scenario: any;
  sessionDurationInterval!: Subscription;

  public sessionInitialised = new BehaviorSubject(false);
  public userDataSet = new BehaviorSubject(false);
  public sessionLogUpdated = new BehaviorSubject(false);
  public variablesChanges = new BehaviorSubject(false);

  allSessions?: Observable<any[]>[] | null | any = {};
  monthlyPlays?: Observable<any[]>[] | null | any = null;
  sessionStats?: Observable<any[]>[] | null | any = {};
  slug = '/scenario_sessions';

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private dataEncryptionService: DataEncryptionService,
    private scenarioService: ScenarioService
  ) {}

  initSession(scenario: any, published?: any, sessionId?: any) {
    let uuid = nanoid(12);
    if (sessionId) {
      uuid = sessionId;
    }
    let user: any = {};

    this.sessionData = {
      id: uuid,
      scenarioId: scenario.id,
      startDate: Date.now(),
      lastActive: Date.now(),
      score: 0,
      vars: [],
      duration: 0,
      responses: [],
      inventory: [],
      currentStepId: 0,
      route: [],
      history: [],
      choices: [],
      user: user,
    };
    this.sessionData.vars = scenario.settings.vars;

    let startStep = this.scenarioService.getStartStep(scenario);
    this.sessionData.route.push(startStep.step_id);
    this.sessionData.history.push(startStep.step_id);
    // do the duration
    const source = interval(1000);
    this.sessionDurationInterval = source.subscribe((val) => {
      this.sessionData.duration++;
    });
    this.sessionInitialised.next(true);

    // any params?
    if (
      scenario.settings.tracking &&
      scenario.settings.tracking.method == 'param'
    ) {
      // get the params
      this.route.queryParams.subscribe((params) => {
        scenario.settings.tracking.params.forEach((param: any) => {
          if (params[param.name]) {
            user[param] = params[param];
          }
        });
        this.setUserData(user);
      });
    }

    // any encrypted params?
    if (
      scenario.settings.tracking &&
      scenario.settings.tracking.method == 'encryption'
    ) {
      // get the params
      this.route.queryParams.subscribe((params) => {
        // decrypt the data
        if (params['pid'] && params['ud']) {
          // do it..
          this.dataEncryptionService
            .decryptData(params['pid'], params['ud'])
            .subscribe((responseData) => {
              try {
                let paramData = JSON.parse(responseData);
                scenario.settings.tracking.params.forEach((param: any) => {
                  if (paramData[param.name]) {
                    user[param.name] = paramData[param.name];
                  }
                });
                this.setUserData(user);
              } catch (error) {}
            });
        }
      });
    }
  }

  resetSession() {
    this.sessionData = null;
    this.sessionDurationInterval.unsubscribe();
    this.sessionInitialised.next(false);
    this.sessionLog = [];
  }

  addToLog(event: string, data: any) {
    let logData: any = {
      event,
      time: Date.now(),
      duration: this.sessionData.duration,
      data,
    };

    if (
      window.API &&
      data.id /*event == 'makeChoice' ||*/ &&
      (event == 'formSubmitted' || event == 'quizCompleted' || event == 'makeChoice' || event == 'goToStep')
    ) {
      // how many interactions?
      let count = window.API.LMSGetValue('cmi.interactions._count');
      window.API.LMSSetValue('cmi.interactions.' + count + '.id', count + '-' + event);
      window.API.LMSSetValue(
        'cmi.interactions.' + count + '.time',
        this.sessionData.duration
      );

      if (event == 'makeChoice') {
        window.API.LMSSetValue('cmi.interactions.' + count + '.type', 'performance');
        window.API.LMSSetValue(
          'cmi.interactions.' + count + '.student_response',
          'Made choice "' + data?.title + '"'
        );
      }
      if (event == 'goToStep') {
        window.API.LMSSetValue('cmi.interactions.' + count + '.type', 'performance');
        window.API.LMSSetValue(
          'cmi.interactions.' + count + '.student_response',
          'Went to step "' + data?.title + '"'
        );
      }

      if (event == 'formSubmitted') {
        window.API.LMSSetValue(
          'cmi.interactions.' + count + '.type',
          'fill-in'
        );
        window.API.LMSSetValue(
          'cmi.interactions.' + count + '.student_response',
          JSON.stringify(data.formattedData)
        );
      }
      if (event == 'quizCompleted') {
        window.API.LMSSetValue('cmi.interactions.' + count + '.type', 'choice');
        window.API.LMSSetValue(
          'cmi.interactions.' + count + '.result',
          +data.score?.toFixed(1)
        );
      }

      // update the journey
      let choicesStr = '';
      this.sessionData.choices.forEach((_choice:any, index:number)=>{
        if(index>0){
          choicesStr+=  ' -> ';
        }
        choicesStr+= '#' + (index+1) + ' "' + _choice.title + '"'; 
      });
      window.API.LMSSetValue(
        'cmi.interactions.0.student_response',
        choicesStr
      );

      window.API.LMSCommit('');
    }
    if (window.API) {
      // update suspend data
      window.API.LMSSetValue(
        'cmi.suspend_data',
        JSON.stringify({
          vars: this.sessionData.vars,
          choices: this.sessionData.choices,
          score: this.sessionData.score,
          stepId: this.sessionData.currentStepId,
        })
      );
      window.API.LMSCommit('');
    }

    this.sessionLog.push(logData);
    this.sessionLogUpdated.next(true);
  }

  setUserData(userData: any) {
    this.sessionData.user = userData;
    this.userDataSet.next(true);
    this.variablesChanges.next(true);
  }
  ngOnDestroy() {
    this.sessionDurationInterval.unsubscribe();
  }

  clearCache() {
    this.allSessions = {};
    this.monthlyPlays = null;
  }

  fetchAll(scenarioId: any): Observable<any[]> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('scenario_id', scenarioId.toString());

    if (!this.allSessions[scenarioId]) {
      this.allSessions[scenarioId] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
          params: searchParams,
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.scenario_sessions;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allSessions[scenarioId];
  }

  fetchMonthlyPlays(month?: string | null, countOnly?: boolean | null) {
    if (!month) {
      const d = new Date();
      month = d.getMonth() + 1 + '-' + d.getFullYear();
    }
    let searchParams = new HttpParams();
    searchParams = searchParams.append('month', month.toString());
    if (countOnly) {
      searchParams = searchParams.append('countOnly', '1');
    }

    this.monthlyPlays = this.http
      .get<any>(environment.apiUrl + this.slug, {
        responseType: 'json',
        params: searchParams,
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.scenario_sessions;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
        publishReplay(1),
        refCount()
      );

    return this.monthlyPlays;
  }

  fetchStats(scenarioId: any): Observable<any[]> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('scenario_id', scenarioId.toString());

    if (!this.sessionStats[scenarioId]) {
      this.sessionStats[scenarioId] = this.http
        .get<any>(environment.apiUrl + '/scenario_session_stats', {
          responseType: 'json',
          params: searchParams,
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.scenario_session_stats[0];
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.sessionStats[scenarioId];
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new ScenarioSession(
            responseData.scenario_session_id,
            responseData.session_id,
            responseData.scenario_id,
            responseData.published_hash,
            responseData.user,
            responseData.session_data,
            responseData.log,
            responseData.score,
            responseData.vars,
            responseData.route,
            responseData.duration,
            responseData.step_id,
            responseData.last_active,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    scenario_id: number,
    session_id: string,
    published_hash: string,
    user: string,
    session_data: string,
    log: string,
    score: string,
    _vars: string,
    route: string,
    duration: string,
    step_id: string,
    last_active: string
  ) {
    this.clearCache();
    const payload = {
      scenario_id,
      session_id,
      published_hash,
      user,
      session_data,
      log,
      score,
      vars: _vars,
      route,
      duration,
      step_id,
      last_active,
    };
    return this.http.post<ScenarioSession>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(
    id: number,
    scenario_id: number,
    session_id: string,
    published_hash: string,
    user: string,
    session_data: string,
    log: string,
    score: string,
    vars: string,
    route: string,
    duration: string,
    step_id: string,
    last_active: string
  ) {
    this.clearCache();
    const payload = {
      scenario_id,
      session_id,
      published_hash,
      user,
      session_data,
      log,
      score,
      vars,
      route,
      duration,
      step_id,
      last_active,
    };
    return this.http.patch<ScenarioSession>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  // function to get vars for pipes
  getVars() {
    let vars = [];
    if (this.sessionData && this.sessionData.vars) {
      vars = this.sessionData.vars;

      // check score and user
      let foundScore = false;
      let foundUser = false;
      vars.forEach((_var: any, index: number) => {
        if (_var.name == 'score') {
          foundScore = true;
          _var.value = this.sessionData.score;
        }
        if (_var.name == 'user') {
          foundUser = true;
          _var.value = this.sessionData.user;
        }
      });
      if (!foundScore) {
        vars.push({
          name: 'score',
          value: this.sessionData.score,
        });
      }
      if (!foundUser) {
        vars.push({
          name: 'user',
          value: this.sessionData.user,
        });
      }
    }

    if (this.sessionData && this.sessionData.vars && this.sessionData.user) {
      // do the user vars...
      for (const [key, value] of Object.entries(this.sessionData.user)) {
        // check it's not in the array already
        var foundIt = false;

        vars.forEach((_var: any, index: number) => {
          if (_var.name == 'user.' + key) {
            foundIt = true;
            _var.value = value;
          }
        });

        if (!foundIt) {
          vars.push({
            name: 'user.' + key,
            value: value,
          });
        }
      }
    }
    return vars;
  }

  fetchSharedSession(hash: any): Observable<any[]> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('hash', hash.toString());

    return this.http
      .get<any>(environment.apiUrl + '/shared_scenario_sessions', {
        responseType: 'json',
        params: searchParams,
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.shared_scenario_sessions[0];
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
